.is-online {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  background-color: #808080;


  &.online {
    background-color: green;
  }
}