
.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

@media (min-width: 768px) {
  .timeline-steps .timeline-step:not(:last-child):after {
    content: "";
    display: block;
    border-top: .25rem dotted #3b82f6;
    width: 3.46rem;
    position: absolute;
    left: 7.5rem;
    top: .3125rem
  }

  .timeline-steps .timeline-step:not(:first-child):before {
    content: "";
    display: block;
    border-top: .25rem dotted #3b82f6;
    width: 3.8125rem;
    position: absolute;
    right: 7.5rem;
    top: .3125rem
  }
}

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6;
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5;
  border: 1px solid;
}


.timeline-step.finished .timeline-content .inner-circle {
  background-color: green;
}

.timeline-step.current .timeline-content .inner-circle {
  background-color: blue;
}

.timeline-step.future .timeline-content .inner-circle {
  background-color: gray;
}

.timeline-steps .timeline-step:not(:first-child)::before {
  border-top: .25rem dotted #a4a4a4;
}

.timeline-steps .timeline-step:not(:last-child)::after {
  border-top: .25rem dotted #a4a4a4;
}

.timeline-steps .timeline-content .inner-circle::before {
  background-color: #e1ecfd;
  opacity: 0.2;
}

.timeline-step.current p.h6 {
  color: #444 !important;
  font-weight: bold;
}

.timeline-steps .timeline-content .inner-circle {
  position: relative;
}

.timeline-steps .timeline-content .inner-circle .stage-number {
  position: absolute;
  color: white;
  font-size: medium;
}

.timeline-step.expired .inner-circle:before {
  background-color: #ff000070;

}

.timeline-content .badge {
  max-width: 150px;
  white-space: wrap;
}